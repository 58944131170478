import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { toast } from "react-toastify";

import InstallPWA from '../InstallPwa';
import { apiURL, userStatus, PostAnyRec, showConfirmationDialog } from "./WmUtils";
import { ButtonLoader } from "./ButtonLoader.jsx";
import * as types from "../shared/types";

const monthlyEmailRiportURL = apiURL + "/monthly-mechanic-workriport/";

const WmNavBar = (props) => {
    const [propState, setPropState] = useState(props);
    const [emailForMonthLoading, setEmailForMonthLoading] = useState(false);
    const [emailForLastMonthLoading, setEmailForLastMonthLoading] = useState(false);

    const sendMonthlyTasksByEmail = async (year, month, setLoading) => {
        const callback = async (res) => {
            if (res !== types.MESSAGEBOX_RESPONSE_YES) return;

            setLoading(true);
            const response = await PostAnyRec(
                monthlyEmailRiportURL,
                {
                    year,
                    month,
                },
            );

            if (response?.status >= 200 && response.status < 300) {
                toast.success(`A havi feladatokat sikeresen elküldtük emailben ${year}.${month}. hónapra.`);
            } else {
                toast.error(`Hiba történt a havi feladatok küldésekor ${year}.${month}. hónapra: ${response?.data?.error || "A szerver nem válaszolt."}`);
            }
            setLoading(false);
        };

        await showConfirmationDialog(
            "Biztosan elküldjük a havi feladatokat emailben?",
            "Havi feladatok küldése emailben",
            callback
        );
    };

    const sendThisMonthsTasksByEmail = async () => {
        await sendMonthlyTasksByEmail(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            setEmailForMonthLoading
        )
    };

    const sendLastMonthsTasksByEmail = async () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);

        await sendMonthlyTasksByEmail(
            date.getFullYear(),
            date.getMonth() + 1,
            setEmailForLastMonthLoading
        )
    };

    useEffect(() => {
        setPropState(props);
      }, [props]);

    return (
            <Navbar bg="primary" variant="dark" expand="sm" collapseOnSelect >
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src="/static/img/icon.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
      Vízóracsere app
    </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav variant="pills" activeKey={propState.activeSelection} className="mr-auto" onSelect={propState.selectionCallBack}>
                    {propState.comStat !== userStatus.logged_out ?
                        <>
                            <Nav.Item>
                                <Nav.Link eventKey="Worklist" title="Worklist">
                                    Feladatok
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <div className="nav-link" onClick={sendThisMonthsTasksByEmail}>
                                    Havi feladat lista küldése emailben
                                    {emailForMonthLoading && <ButtonLoader />}
                                </div>
                            </Nav.Item>
                            <Nav.Item >
                                <div className="nav-link" onClick={sendLastMonthsTasksByEmail}>
                                    Múlt feladat havi lista küldése emailben
                                    {emailForLastMonthLoading && <ButtonLoader />}
                                </div>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link eventKey="Logout" title="Logout">
                                    Kijelentkezés
                                </Nav.Link>
                            </Nav.Item>
                            </>
                            :
                            <Nav.Item>
                                <Nav.Link eventKey="Login" title="Login">
                                    Bejelentkezés
                            </Nav.Link>
                            </Nav.Item>
                        }
                    </Nav>
                </Navbar.Collapse>
                <InstallPWA />

            </Navbar>

    );
}
export default  WmNavBar

import backendErrorMessage from "../shared/backendError";
import { showBackendError } from "../app/ShowError";
import { userStatus, apiURL, PostAnyRec, PatchAnyRec, GetAnyList, PostPhoto } from "../app/WmUtils";
import { toast } from "react-toastify";
import * as types from "../shared/types";

/*eslint-disable eqeqeq*/

export const upLoadWorkSheet = async (propState, onSuccess) => {
  let upload_without_error = true;

  const anyListToDB = async (newState, apiItemStr) => {

    const processToDB = async (task_item) => {
      const imgList = task_item.images;
      const resolveBool = (item_name) =>
        task_item[item_name] == "true" || task_item[item_name] == true ? true : task_item[item_name] == "false" || task_item[item_name] == false ? false : null;
      const wmData =
        apiItemStr === "/wmeters/"
          ? {
              is_new: resolveBool("is_new"),
              is_cold: resolveBool("is_cold"),
              watermeter_physical_place: task_item.watermeter_physical_place,
              size: task_item.size,
              meter_ok: resolveBool("meter_ok"),
              act_meter: task_item.act_meter,
              meter_identifier: task_item.meter_identifier,
              plomba1_identifier: task_item.plomba1_identifier,
              plomba1_ok: resolveBool("plomba1_ok"),
              plomba2_identifier: task_item.plomba2_identifier,
              plomba2_ok: resolveBool("plomba2_ok"),
              image_filenames: imgList.map((item) => item.name),
              note: task_item.note,
              wm_number: task_item.wm_number,
              t_id: propState.actTask,
            }
          : {
              description: task_item.description,
              price: task_item.price,
              image_filenames: imgList.map((item) => item.name),
              note: task_item.note,
              t_id: propState.actTask,
            };
      const respWmeter = task_item.id
        ? await PatchAnyRec(`${apiURL}/tasks/${propState.actTask}${apiItemStr}${task_item.id}`, wmData)
        : await PostAnyRec(`${apiURL}/tasks/${propState.actTask}${apiItemStr}`, wmData);
      if (respWmeter && respWmeter.status == (task_item.id ? 200 : 201) && respWmeter.data) {
        task_item.id = respWmeter.data.id;
        task_item.serial = respWmeter.data.serial;

        task_item.images.forEach(async (d) => {
          let imgFileData = new FormData();
          imgFileData.append("name", d.name);
          imgFileData.append("photo", await (await fetch(d.image)).blob(), d.name);
          let retVal = await PostPhoto(`${apiURL}/tasks/${propState.actTask}${apiItemStr}${task_item.id}/photos/`, imgFileData);

          if (retVal.status == 201 && retVal.data) {
            const url_filepart = retVal.data.photo.split("/");
            d.name = url_filepart[url_filepart.length - 1];
          } else {
            showBackendError(backendErrorMessage("Hiba a fotó feltöltésekor. ", retVal));
            upload_without_error = false;
          }
          return d;
        });

        return task_item;
      } else {
        showBackendError(backendErrorMessage(`Hiba ${apiItemStr === "/wmeters/" ? "a vízóra" : "az egyéb munka"}  adatok feltöltésekor. `, respWmeter));
        upload_without_error = false;
        return task_item;
      }
    };

    //process the whole list synchronously, the wmeters of the wmlist can contain the same protecting components, which can cause intergrity error
    //better to handle synchronously, and the customer receives clearer error messages
    for (let i=0;i<newState[`${propState.actTask}`].wmlist.length;++i)
    {
      let task_item=newState[`${propState.actTask}`].wmlist[i];
      newState[`${propState.actTask}`].wmlist[i] = await processToDB(task_item);
    };
  };
  const anyIDListToDB = async (actIDList, apiItemStr) => {
    actIDList.forEach(async (idii) => {
      const idData =
        apiItemStr === "/watermeters/"
          ? {
              [types.PREFIX_WM]: idii[types.PREFIX_WM],
              duty: idii.duty,
            }
          : {
              [types.PREFIX_SEAL]: idii[types.PREFIX_SEAL],
              duty: idii.duty,
            };
      const respID = await PatchAnyRec(`${apiURL}${apiItemStr}${idii.id}/`, idData);
      if (!respID || respID.status != 200) {
        showBackendError(backendErrorMessage(`Hiba ${apiItemStr === "/watermeters/" ? "a vízóra" : "a plomba"} azonosító adatok feltöltésekor. `, respID));
        upload_without_error = false;
      }
    });
  };
  if (propState.wmWorkList[`${propState.actTask}`]) {
    if (propState.wmWorkList[`${propState.actTask}`].wmlist.some((wii) => !wii.images || wii.images.length < 1)) {
      toast.error(
        <div>
          A munka adatai nem kerültek adatbázisba:
          <br />
          Győződjön meg róla, hogy az összes vízórához mellékelt fotót!
        </div>
      );
      return;
    }
  }

  if (propState.comStat === userStatus.offline) {
    toast.warning(
      <div>
        Az alkalmazás offline állapontban van,
        <br /> nem lehetséges a munkalap nyomtatás
      </div>
    );
    return;
  }
  if (propState.otherWorkList[`${propState.actTask}`]) {
    let newState = Object.assign({}, propState.otherWorkList);

    await anyListToDB(newState, "/invoiceitems/");

    propState.SetOtherWorkList(newState);
  }

  if (propState.wmWorkList[`${propState.actTask}`]) {
    let newState = Object.assign({}, propState.wmWorkList);

    await anyListToDB(newState, "/wmeters/");

    propState.wmList.current = propState.wmList.current
      .filter((wmii) => wmii.duty !== null)
      .map((wmii) => {
        /// The trick here is that watermeter.duty stores the index of the related wmeter,
        /// but not the id of that till this moment

        wmii.duty = newState[`${propState.actTask}`].wmlist[wmii.duty].id;
        return wmii;
      });

    await anyIDListToDB(propState.wmList.current, "/watermeters/");

    const retWmList = await GetAnyList(`${apiURL}/watermeters/`);
    if (!retWmList || !retWmList.status || retWmList.status !== 200) {
      showBackendError(backendErrorMessage("Hiba a vízórakészlet adatok letöltésekor: ", retWmList));
      upload_without_error = false;
      propState.wmList.current = null;
    } else {
      propState.wmList.current = retWmList.data.map((item) => {
        let idx = newState[`${propState.actTask}`].wmlist.findIndex((wii) => wii.meter_identifier == item[types.PREFIX_WM]);
        if (!!(idx > -1)) {
          item.duty = idx;
        } else {
          item.duty = null;
        }
        item.isSaved = true;
        return item;
      });
    }

    propState.sealList.current = propState.sealList.current
      .filter((wmii) => wmii.duty !== null)
      .map((wmii) => {
        /// The trick here is that seal.duty stores the index of the related wmeter,
        /// but not the id of that till this moment
        wmii.duty = newState[`${propState.actTask}`].wmlist[wmii.duty].id;
        return wmii;
      });
    await anyIDListToDB(propState.sealList.current, "/seals/");

    const retSealList = await GetAnyList(`${apiURL}/seals/`);
    if (!retSealList || !retSealList.status || retSealList.status !== 200) {
      showBackendError(backendErrorMessage("Hiba a plomba adatok letöltésekor: ", retSealList));
      upload_without_error = false;
      propState.sealList.current = null;
    } else {
      propState.sealList.current = retSealList.data.map((item) => {
        let idx = newState[`${propState.actTask}`].wmlist.findIndex((wii) => wii.plomba1_identifier == item[types.PREFIX_SEAL]);
        if (!!(idx > -1)) {
          item.duty = idx;
        } else {
          idx = newState[`${propState.actTask}`].wmlist.findIndex((wii) => wii.plomba2_identifier == item[types.PREFIX_SEAL]);
          if (!!(idx > -1)) {
            item.duty = idx;
          } else {
            item.duty = null;
          }
        }
        return item;
      });
    }
    propState.SetWmWorkList(newState);
  }
  const currentTask = propState.taskList.find((x) => x.task_id === propState.actTask);

  const workData = {
    invoice: {
      address: {
        route: currentTask.invoice_address_route ? currentTask.invoice_address_route : propState.invoiceData.address_route,
        street_number: currentTask.invoice_address_street_number ? currentTask.invoice_address_street_number : propState.invoiceData.address_street_number,
        city: currentTask.invoice_address_city ? currentTask.invoice_address_city : propState.invoiceData.address_city,
        country: currentTask.invoice_address_country ? currentTask.invoice_address_country : currentTask.work_address_country,
        postal_code: currentTask.invoice_address_postal_code ? currentTask.invoice_address_postal_code : currentTask.work_address_postal_code,
        // New records according to VZ-239
        building: currentTask.invoice_address_building ? currentTask.invoice_address_building : currentTask.work_address_building,
        floor: currentTask.invoice_address_floor ? currentTask.invoice_address_floor : currentTask.work_address_floor,
        door: currentTask.invoice_address_door ? currentTask.invoice_address_door : currentTask.work_address_door,
        doorbell: currentTask.invoice_address_doorbell ? currentTask.invoice_address_doorbell : currentTask.work_address_doorbell,
        // EO New records according to VZ-239

        // Dummy place_id, latitude and longitude for the invoice address,
        // because the invoice address is not an address that we need that information for.
        // It's only for the invoice. We don't need to hit the Google API again.
        place_id: "DUMMY_PLACE_ID",
        latitude: "123",
        longitude: "123",
      },
      company_name: currentTask.invoice_company_name ? currentTask.invoice_company_name : currentTask.work_contact_name,
      tax_number: currentTask.invoice_tax_number ? currentTask.invoice_tax_number || currentTask.work.tax_number : "-",
    },
    address: {
      route: currentTask.work_address_route,
      street_number: currentTask.work_address_street_number,
      city: currentTask.work_address_city,
      country: currentTask.work_address_country,
      postal_code: currentTask.work_address_postal_code,
      building: currentTask.work_address_building,
      floor: currentTask.work_address_floor,
      door: currentTask.work_address_door,
      doorbell: currentTask.work_address_doorbell,
    },
    watermeter_physical_place: currentTask.work_watermeter_physical_place,
    price: currentTask.work_price,
    discount_percentage: currentTask.work_discount_percentage,
    contact_name: currentTask.work_contact_name,
    contact_phone: currentTask.work_contact_phone,
    contact_email: currentTask.work_contact_email,
    note: currentTask.work_note,
    job: currentTask.work_job_fk,
  };

  const taskData = {
    process_type: currentTask.task_process_type == "true" ? true : false,
    note_mech_to_disp: currentTask.task_note_mech_to_disp,
    work: workData
  };

  const respTask = await PatchAnyRec(`${apiURL}/tasks/${propState.actTask}/`, taskData);

  if (!respTask || respTask.status !== 200) {
    showBackendError(backendErrorMessage("Hiba a feladat adatok feltöltésekor. ", respTask));
    upload_without_error = false;
  }
  if (upload_without_error) {
    toast.success("Adatok elmentve");
    await onSuccess();
  }
};
